.avatar {
  /* margin-left: 30px; */
  padding-top: 8px;
}

.username {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  padding-right: 12px;
  padding-top: 6px;
  margin-left: 8px;
  color: black;
}

.brand {
  margin-top: 20px;
}

.toggle {
  border: none !important;
  padding: 0px;
}

.toggle:focus {
  border: none !important;
}

.link {
  font-size: 16px !important;
  font-weight: bold !important;
  padding-top: 12px !important;
  color: black !important;
  white-space: nowrap;
}

.active {
  text-decoration: underline 2px #0f32af !important;
  text-underline-offset: 8px;
}

.wallet {
  border: 1px solid #c3ccd7;
  height: 48px;
  padding: 4px 5px 4px 10px;
  display: flex;
  gap: 14px;
  align-items: center;
}

.balance {
  max-width: 160px;
  margin-right: 0px !important;
}

.balanceText {
  font-size: 12px;
  font-weight: 400;
}

.balanceValue {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 24;
}

.badge {
  color: #687076 !important;
  font-weight: 600 !important;
  border: 1px solid #687076 !important;
  background-color: white !important;
}

.activeBadge {
  color: white !important;
  border: 1px solid #687076 !important;
  background-color: black !important;
  font-weight: 600;
  padding-bottom: 6px;
}

@media screen and (max-width: 992px) {
  .avatar {
    margin-left: 0px;
  }

  .link {
    padding-top: auto;
  }

  .active {
    text-decoration: none !important;
  }
}

.navUser {
  color: red;
}

@media screen and (max-width: 430px) {
  .avatar {
    height: 32px !important;
    padding-top: 5px;
  }
  .wallet {
    height: 28px !important;
    width: 100% !important;
    justify-content: space-around;
  }
}