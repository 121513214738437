.crow {
    padding-bottom: 16px;
}

.text {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.12px;
}

.label {

    color: #0A0C19;
    padding-bottom: 2px;
}

.fields {
    width: 100%;
    border: 2px solid #BFC2D4;
    border-radius: 4px;
    padding: 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #7E7C96;
}

.numberCard {
    border-bottom: none;
    border-radius: 4px 4px 0 0;
}

.expiry {
    border-radius: 0 0 0 4px;
}

.cvc {
    border-left: none;
    border-radius: 0 0 4px 0;
}

.agreetext {
    font-size: 12px;
    font-weight: 500;
    line-height: 16.39px;
}

.button {
    height: 48px;
    padding: 10px 28px;
    border-radius: 4px;
}

.cancelButton {
    border: 2px solid #565468;
    color: #565468;
}

.payButton {
    border: none;
    color: white;
    background-color: black;
}

.inputMoney {
    width: 100%;
    padding: 12px;
    padding-left: 22px;
    border-radius: 4px;
    border: 2px solid #BFC2D4;
    box-shadow: 0px 1px 2px 0px #E4E5E73D;
}

.inputText {
    color: #7E7C96;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
}

.dollar{
    position: absolute;
    top: 14px;
    left: 24px;
}