.inputContainer {
    position: relative;
    width: 80px;
    margin-left: 12px;
}

.inputContainerWithoutSliders {
    display: flex;
    gap: 16px;
    position: relative;
    width: 100%;
}

.input {
    width: 80px;
}

.inputWithoutSliders {
    width: 100%;
}

.errorContainer {
    position: absolute;
    right: 0;
}

.errorContainerNonSlider {
    position: absolute;
    right: 0;
    top: 44px;
}

.errorText {
    font-size: 10px !important;
    text-wrap: nowrap;
    font-weight: 600;
    color: gray;
}