.payment {
    background-color: white;
    padding: 24px;
}

.bigText {
    font-weight: 700;
    font-size: 24px !important;
    line-height: 26.4px;
    padding-bottom: 20px;
    color: black;
}

.smallText {
    font-weight: 700;
    font-size: 14px;
    line-height: 16.8px;
    color: #3F3D3D;
}

.balanceContainer{
    padding-right: 60px;
}

@media screen and (max-width: 978px) {
    .balanceContainer {
        padding-right: 0px;
    }
}

.balanceValue {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    color: #0F32AF;
    padding-bottom: 20px;
}

.inputMoney {
    width: 100%;
    padding: 12px;
    padding-left: 22px;
    border-radius: 4px;
    border: 2px solid #BFC2D4;
    box-shadow: 0px 1px 2px 0px #E4E5E73D;
}

.inputText {
    color: #7E7C96;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
}

.dollar{
    position: absolute;
    top: 14px;
    left: 24px;
}

.quickMoneyContainer {
    display: flex; /* Flexbox */
    justify-content: center;
    white-space: nowrap; /* Запрет на перенос текста */
    width: 47px;
    height: 21px;
    padding: 0px 10px 3px 6px;
    border-radius: 4px;
    background-color: #E8F0FB;
    font-size: 12px;
    font-weight: 500;
    line-height: 16.39px;
    color: #3F3D3D;
    text-align: center;
    cursor: pointer;
}

.quickMoneyText {
    display: inline-block;
    height: 21px !important;
    padding-top: 2.5px !important;
}

.quickMoneyTextActive {
    background-color: #C5E0FE;
}


.extraSmallText {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.39px;
    color: #000000;
    padding-bottom: 16px;
}

.btn {
    width: 65px;
    height: 36px;
    border-radius: 4px;
    border: none;
    color: white;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.12px;
    background: #CBCBCB;
}

.button {
    height: 36px;
    padding: 0px 28px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.12px;
    padding: 8px 20px 9px 20px;
}


.payButton {
    border: none;
    color: white;
    background-color: black;
}

.payButton:disabled {
    background: #CBCBCB;
    color: white;
    border: none;
}

.btnAddPayment {
    height: 36px;
    padding: 8px 16px 9px 16px;
    border-radius: 4px;
    border: 1.5px solid #565468;
    background-color: white;
}