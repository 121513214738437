.cantFindModelContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  padding-top: 8px;
}

.cantFindModelText {
  padding-top: 2px;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0px;
}

@media screen and (max-width: 576px) {
  .cantFindModelContainer {
    justify-content: flex-start;
  }
}
