.modal {
    max-width: 844px !important;
    color: red;
}

.valueContainer {
    min-width: fit-content;
    height: 70px;
    padding: 8px 12px 8px 12px;
    border-radius: 4px;
    background: #E9EDF480;
}

.bigText {
    font-weight: 700;
    font-size: 24px !important;
    line-height: 26.4px;
    padding-bottom: 20px;
    color: black;
}

.valueContainerSmallText {
    font-size: 14px;
    font-weight: 700;
    line-height: 16.8px;
}

.valueContainerBigText {
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    color: #3F3D3D;
    padding-top: 8px;
}

.btn {
    width: 65px;
    height: 36px;
    border-radius: 4px;
    border: none;
    color: white;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.12px;
    background: #CBCBCB;
}

.autoPaymentText {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.12px;
}

.crow {
    padding-bottom: 16px;
}

.fields {
    width: 100%;
    max-height: 48px !important;
    border: 2px solid #BFC2D4;
    border-radius: 4px;
    padding: 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #7E7C96;
}

.button {
    height: 36px;
    padding: 0px 28px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.12px;
}


.payButton {
    border: none;
    color: white;
    background-color: black;
}

.payButton:disabled {
    background: #CBCBCB;
    color: white;
    border: none;
}

.cancelButton {
    height: 36px;
    padding: 0px 16px 0px 16px;
    border-radius: 4px;
    border: 1.5px solid #565468;
    background-color: white;
}

.save {
    min-height: 300px;
    text-align: center ;
    padding-top: 120px;
}
