.main {
  margin: 0;
  background-color: white;
  min-height: 322px;
  padding: 48px;
}

.text {
  font-size: 18px;
  line-height: 140%;
  margin-bottom: 24px;
}