.smallButton {
    height: 36px;
    font-weight: 600;
    border-radius: 4px;
    border: none;
    background-color: black;
    color: white;
    padding: 0 36px;
}

.smallBorderedButton {
    background-color: white;
    font-weight: 600;
    height: 36px;
    border: 1.5px solid gray;
    border-radius: 4px;
    color: gray;
    padding: 0 36px;
}