.breadCrumbs{
    font-size: 14px;
    color: #595C74;
    padding-bottom: 16px;
}

.projectNameTitle {
    font-size: 36px;
    font-weight: bold;
    word-break: break-all;
}

.deployButton {
    width: 180px;
    height: 36px;
    border-radius: 4px;
    border: none;
    background-color: black;
    color: white;
}
