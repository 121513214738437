    .searchContainer{
        height: 49px;
        background-color: #E9EDF4 !important;
    }
    .inputField {
        height: 40px !important;
        width: calc(100% - 27px);
        padding-left: 10px;
        border: none;
        background-color: #E9EDF4 ;
    }