.main {
    padding: 24px 12px;
    margin: 0;
    background-color: white;
}

.button {
    width: 73px;
    height: 36px;
    border-radius: 4px;
    border: none;
    background-color: black;
    color: white;
    justify-content: space-between;
}

.label {
    font-size: 14px;
    font-weight: bold;
}

.monitoringMain {
    display: flex;
    justify-content: flex-end;
}

.deploySettingsMain {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.monitoring {
    padding-left: 24px;
    width: 90%;
}

.refresh {
    width: 102px;
    border: 1px solid gray;
    border-radius: 4px;
    color: gray;
    font-size: 14px;
}

.podsCount {
    font-size: 14px;
    color: gray;
    padding-left: 2px;
}

@media (max-width: 991px) {
    .main {
        padding: 24px 12px;
        margin: 0;
        background-color: white;
        flex-direction: column;
    }

    .monitoringMain {
        padding-top: 24px;
    }

    .deploySettings {}

    .monitoring {
        padding-left: 0;
        width: 100%;
    }
}