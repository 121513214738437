.react-tabs__tab-list {
    z-index: 0;
    display: flex;
    overflow-x: scroll; /* Измените на scroll вместо auto */
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    scrollbar-color: #cccccc transparent;
    white-space: nowrap;
}

.react-tabs__tab--selected {
    color: #7828c8 !important;
    background: transparent !important;
    border-radius: 10px !important;
    border-color: #7828c8 !important;
    border-width: 2px !important;
    border-style: solid !important;
}

.react-tabs__tab--selected::after {
    background: transparent !important;
    border-style: none !important;
}

.react-tabs__tab {
    color: #7828c8;
    background: #f0f0f0;
    border-radius: 10px;
    margin: 0 5px 0 10px;
}

