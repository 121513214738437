.main{
    position: relative;
}
.image {
    width: 24px;
    height: 24px;
    padding-bottom: 2px;
}

.copied {
    width: max-content;
    position: absolute;
    color: green;
    font-size: 10px;
    top: 39px;
    left: 0;
}