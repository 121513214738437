.text {
    height: 24px;
    font-weight: 700;
    font-size: 16px !important;
    line-height: 21.86px;
    padding-left: 6px;
    padding-bottom: 5px;
    display: inline-block;
    padding-top: 0px !important;
}

.img {
    height: 24px;
    padding-top: 9px;
    display: inline-block;
}
