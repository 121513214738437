.main {
    display: flex;
    justify-content: space-between;
    padding: 12px;
}

.name {
    min-width: 120px;
    font-size: 18px;
    font-weight: bold;
}
.showSetting {
    display: flex;
    justify-content: flex-end;
    min-width: 100px;
    font-size: 4px !important;
}