.container {
    background-color: white;
    padding: 24px 0;
}

.table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #C3CCD7;
}

.small{
    width: 10%;
}

.middle{
    width: 15%;
}

.caption {
    caption-side: top;
}

.thead {
    background-color: #E9EDF4;
    height: 56px;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: #565468;
}

.tbody {
    max-height: 200px !important;
    overflow-y: scroll;
    scrollbar-gutter: none;
}

.thtd {
    border: 1px solid #C3CCD7;
}

.in td {
    border: 1px solid #C3CCD7;
    height: 56px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #0A0C19;
}

.status {
    height: 27px;
    padding: 3px 12px 4px 12px;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    text-wrap: nowrap;
}