.main {
    padding-bottom: 16px;
    padding-top: 16px;
}

.state {
    width: fit-content;
    padding: 2px 8px 2px 8px;
    border-radius: 2px;
    line-height: 140%;
    font-size: 16px;
    font-weight: 700;
}

.text {
    display: inline;
    width: min-content;
    font-size: 16px;
    font-weight: 700;
    padding: 8px 0;
}

.image {
    height: 15px;
    margin-bottom: 3px;
}

.title {
    color: gray;
}

.created_at{
    padding-left: 8px;
    color: gray;
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
}

.date {
    color: #565468;
}
