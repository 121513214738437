.main {
    padding: 24px 24px;
    margin: 0;
    background-color: white;
}

.title {
    padding-bottom: 10px;
    line-height: 110%;
}

.section {
    width: 100%;
}

.codeBlock {
    width: 100%;
    background-color: #F6F7F8;
    border-radius: 4px;
    border: none;
    position: relative;
}

.buttonContainer {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
}

.button {
    border: 1.5px solid #565468;
    border-radius: 4px;
    color: #565468;
    font-size: 14px;
    font-weight: 700;
    padding: 8px 16px 9px 16px;
}

@media screen and (max-width: 615px) {
    .buttonContainer {
        position: relative;
        justify-content: flex-end;
    }
}