.settingLabel {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 24px;
}

.button {
    width: 73px;
    height: 36px;
    border-radius: 4px;
    border: none;
    background-color: black;
    color: white;
}

.label {
    padding-bottom: 4px;
    font-size: 14px;
    font-weight: bold;
}

.select {
    padding: 0 12px 0 12px  ;
    width: 100%;
    height: 40px;
    font-size: medium;
}

.selectDisabled {
    padding: 0 12px 0 12px  ;
    width: 100%;
    height: 40px;
    font-size: medium;
    color: gray;
    border-color: #7E7C96;
    color: #7E7C96;
}

.minMaxValue {
    font-size: 12px;
    color: gray;
}

.activePods {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.monitoringMain{
    padding: 0px;
    padding-top: 24px;
    display: flex;
}

.monitoring {
    padding-left: 0px;
    width: 544px;
}

.refresh {
    background-color: white;
    font-weight: bold;
    width: 102px;
    height: 36px;
    border: 1.5px solid gray;
    border-radius: 4px;
    color: gray;
    font-size: 12px;
}

.podsCount {
    font-size: 14px;
    color: gray;
    padding-left: 2px;
    font-weight: normal;
}