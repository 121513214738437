*,
*::before,
*::after {
    box-sizing: border-box;
}

.interactive {
    position: relative;
    border-radius: 4px;
    z-index: 0;
    overflow: hidden;
    padding: 2px 12px;
    margin-top: 16px;
}

.interactive:hover::before {
    content: '';
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -100%;
    width: 200%;
    height: 300%;
    background-repeat: no-repeat;
    background-position: 0 0;
    border-radius: 100px;
    background-image: conic-gradient(transparent, #0F32AF, transparent 30%);
    animation: rotate 4s linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(1turn);
    }
}

.main {
    width: calc(100% + 20px) !important;
    padding: 0px;
    border-radius: 2px;
    background-color: #E9EDF4;
    margin: 0 !important;
    margin-left: -10px !important;
    height: auto;
    overflow: hidden;
}

.image {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
}

.runs {
    position: absolute;
    bottom: 17px;
    left: 12px;
    background-color: rgb(255, 253, 253, 0.8);
    color: gray;
    font-size: 1rem;
}

.runsValue {
    color: black;
    font-weight: bold;
    font-size: 14px;
    padding-left: 2px;
}

.lightingContainer {
    position: absolute;
    text-align: center;
    padding-top: 6px;
    top: 8px;
    left: 8px;
    background: rgb(249, 249, 249, 0.8);
    width: 40px;
    height: 40px;
    border-radius: 20px;
}

.imageContainer {
    position: relative;
    padding: 0 !important;
    margin: 0px;
}

.desc {
    padding: 24px 0px 16px 20px;
    max-height: 6rem !important;
}

.title {
    overflow-wrap: break-word;
    word-wrap: break-word !important;
    white-space: normal;
    overflow: hidden;
    min-height: 3.5rem;
    max-height: 3.5rem;
    font-size: 18px;
    font-weight: bold;
    color: black;
}

.description {
    padding-top: 15px;
    height: calc(7rem - 14px);
    overflow: hidden;
    color: gray;
    font-size: 14px;
}

.tags {
    padding-top: 15px;
    height: 0.5rem;
}

.link {
    text-decoration: none;
}

@media (max-width: 1200px) {
    .desc {
        height: auto;
        max-height: 29rem !important;
    }
}