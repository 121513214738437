.titleContainer {
    display: flex !important;
    align-items: center;
    max-height: 64px;
}

.imageContainer {
    height: 44px;
}

.title {
    font-size: 18px;
    font-weight: bold;
    line-height: 140%;
    padding: 0 24px 0 12px;
}

.main {
    max-width: 100% !important;
    position: relative;
    max-height: 64px;
}

.cardContainer {
    display: flex !important;
    width: 100%;
    flex: 251px;
    gap: 24px;
    overflow-wrap: scroll !important;
    overflow-x: auto !important;
    white-space: wrap !important;
    position: relative;
}

.main::after {
    content: '';
    position: absolute;
    top: -1px;
    right: -2px;
    width: 20%;
    height: 110%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), white 92%);
    pointer-events: none;
    z-index: 1;
}

.cardContainer::-webkit-scrollbar {
    width: 0;
}

.link {
    text-decoration: none;
}

.card {
    width: max-content !important;
    background-color: black;
    border-radius: 4px;
    min-width: fit-content;
    min-height: 64px;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.image {
    width: 64px;
    height: 64px;
    filter: blur(0.8px);
    background-color: rgba(198, 192, 192, 0.349);
 }

.text {
    font-size: 16px;
    font-weight: bold;
    color: white;
    padding: 0px 24px 0px 16px;
}

@media screen and (max-width: 770px) {
    .titleContainer{
        margin-bottom: 16px;
    }
}