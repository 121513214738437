.navbar .dropdown-toggle::after {
    display: none !important;
}

.custom-toggle {
    border: none !important;
    padding: 0px !important;
}

.custom-toggle:focus {
    border: none;
    padding: 0px;
}

a {
    text-decoration: none !important;
}

.navbar-nav .dropdown-toggle {
}

.navbar .dropdown-menu{
    min-width: 20rem !important;
    padding: 12px 6px 10px 6px;
}

.navbar-nav .dropdown-menu{
    right: -180px !important;
    left: auto !important;
}

@media screen and (max-width: 1650px){
    .navbar-nav .dropdown-toggle .show{
    }
    .navbar-nav .dropdown-menu{
        right: -120px !important;
        left: auto !important;
    }
}
@media screen and (max-width: 1560px){
    .navbar-nav .dropdown-toggle .show{
    }
    .navbar-nav .dropdown-menu{
        right: -80px !important;
        left: auto !important;
    }
}

@media screen and (max-width: 1460px){
    .navbar-nav .dropdown-toggle .show{
    }
    .navbar-nav .dropdown-menu{
        right: -70px !important;
        left: auto !important;
    }
}
@media screen and (max-width: 1380px){
    .navbar-nav .dropdown-toggle .show{
    }
    .navbar-nav .dropdown-menu{
        right: -50px !important;
        left: auto !important;
    }
}
@media screen and (max-width: 1000px){
    .navbar-nav .dropdown-toggle .show{
    }
    .navbar-nav .dropdown-menu{
        right: -10px !important;
        left: auto !important;
    }
}

.StripeElement .__PrivateStripeElement {
    border: 1px solid black !important;
}