.main {
    background-color: #E9EDF4;
    min-height: 80px;
    padding: 0;
    padding-top: 6px;
}

.linkFooter {
    font-size: 16px;
    font-weight: 700;
    line-height: 21.86px;
    color: gray;
    text-decoration: none;
    padding-right: 10px;
}

.text {
    padding-top: 12px;
    font-size: 12px;
}

@media screen and (max-width: 992px) {
    .main {
        padding-bottom: 16px;
    }
}