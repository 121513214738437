.main {
    margin: 0;
    padding: 0;
    min-height: 544px;
    background-color: white;
    display: flex;
    flex-direction: row;
}


.spacer {
    height: 2rem;
}

.state {
    padding-top: 24px;
    padding-left: 24px !important;
    flex: 1;
    position: relative;
}

.name {
    min-width: 120px;
    font-size: 14px;
    font-weight: bold;
}

.showSetting {
    min-width: 100px;
}

.description {
    font-size: 16px;
    color: #5B5B5B;
}

.btnContainer {
    min-height: 250px !important;
}

.button {
    width: 120px;
    height: 56px;
    border-radius: 4px;
}

.buttonRun {
    border: none;
    background-color: black;
    color: white;
    font-size: 16px;
}

.buttonReset {
    border: 2px solid gray;
    color: #565468;
    background-color: white;
    font-weight: 500;
}

.button:disabled {
    background-color: #CBCBCB;
    color: white;
    border: none;
}

.res {
    padding: 0px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    flex: 1;
}

.resultField {
    word-break: break-word;
    white-space: pre-wrap;
    background-color: transparent;
    overflow-y: scroll;
    padding: 12px !important;
    font-size: 16px;
    color: #5B5B5B;
    line-height: 150%;
}

.res::before {
    content: '';
    position: absolute;
    left: 10%;
    top: 0;
    bottom: 0;
    border-left: 2px dashed #C3CCD7;
}

.runButtonDiv {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    gap: 10px;
}

.resultContainer {
    padding: 24px 12px 24px 24px;
    height: 100%;
    width: 90%;
    overflow-y: auto;
}

.resultContainer>* {
    width: 100%;
}

.sampleImage {
    aspect-ratio: 1/1;
    position: relative;
    height: auto !important;
    width: 100% !important;
    background: linear-gradient(115deg, #d9e4ee40, #ffffff00 20%, #DCE5EC 45%, #ffffff00 70%, #d9e4ee40) !important;
    border: none !important;
}

.sampleImage span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    transform: translateX(-50%);
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    color: #565468;
}

.containerBtnResult {
    border: none !important;
    display: flex;
    justify-content: flex-end;
    padding: 10px 0 10px 0 !important;
}

.buttonResult {
    height: 36px;
    width: 118px;
    color: gray;
    font-size: 14px;
    font-weight: bold;
    border-radius: 4px;
    border: 1.5px solid gray;
}


@media (max-width: 991px) {
    .main {
        flex-direction: column;
    }

    .res::before {
        display: none;
    }

    .resultContainer {
        width: 100%;
        padding-right: 12px;
        padding-left: 12px;
    }
}

.resultContent {
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
}


.result {
    text-align: center;
    vertical-align: bottom !important;
}

.instantGeneratingContainer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
}

.inputContainer {
    margin-bottom: 0px;
}

.labelContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.outputLabel {
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.inputLabel {
    font-weight: bold;
}

.switchContainer {
    flex: 0 0 auto;
}

.inputWrapper {
    width: 100%;
}

.instantGeneratingContainer {
    display: flex;
    align-items: center;
}

.inputWrapper *,
.outputWrapper *,
.resultContainer * {
    box-shadow: none !important;
}

.inputWrapper input,
.inputWrapper textarea {
    border: 2px solid #90A0B5 !important;
    border-radius: 4px !important;
    background-color: white !important;
    padding: 8px 12px 8px 12px !important;
    margin: 0 !important;
    max-height: 100%;
    overflow-y: auto;
}

.outputWrapper>div,
.resultContainer>div>div {
    border: 2px solid #90A0B5;
    border-radius: 4px !important;
    padding: 2px 12px 0 12px;
    margin: 0 !important;
    max-height: 100%;
    overflow-y: auto;
}

.resultContainer div {
    width: 100%;
}


.outputMediaWrapper {
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    max-height: 100%;
    overflow-y: auto;
}


.inputWrapper>div[class*="nextui-c-"]>div[class*="nextui-input-wrapper"] {
    border: 1px dashed #E0E0E0 !important;
}

.inputWrapper input,
.inputWrapper textarea {
    color: inherit !important;
}

.inputWrapper input::placeholder,
.inputWrapper textarea::placeholder {
    color: #999 !important;
}

.fileInputContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.inputWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.fileInput {
    flex-grow: 1;
    width: 100%;
}

.chooseFileButton {
    padding: 8px 16px;
    border: 2px solid #90A0B5;
    border-radius: 4px;
    font-weight: 500;
    color: #000000;
    font-size: 14px;
    background-color: white;
    cursor: pointer;
}

.recordButton {
    width: 36px;
    height: 36px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fakeFileInput {
    display: none;
}


@media screen and (max-width: 992px) {
    .btnContainer {
        min-height: 50px !important;
    }
}

.videoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.videoElement {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.captureButtons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.previewContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.previewImage {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.previewContainer button {
    margin-top: 10px;
}

.smallBorderedButton {
    background-color: white;
    font-weight: bold;
    height: 36px;
    border: 1.5px solid gray;
    border-radius: 4px;
    color: gray;
    font-size: 12px;
}

.smallButton {
    height: 36px;
    border-radius: 4px;
    border: none;
    background-color: black;
    color: white;
}

.instantGeneratingLoading {
    width: fit-content !important;
    min-width: auto !important;
    margin-left: 8px !important;
    padding-top: 4px;
}
