.state {
    width: fit-content;
    padding: 2px 8px 2px 8px;
    border-radius: 2px;
    line-height: 140%;
    font-size: 16px;
    font-weight: 700;
}

.text {
    display: inline;
    width: min-content;
    font-size: 16px;
    font-weight: 700;
    padding: 8px 0;
    height: 33px;
}