.container {
    display: inline-flex;
    min-height: 45px;
    width: 100% !important;
    box-sizing: border-box;
    gap: 6px;
    padding-bottom: 4px;
    border: 1px solid white;
    border-radius: 4px;
}

.container:hover {
    background-color: rgba(191, 194, 212, 0.2);
}

.brandImage {
    max-height: 45px;
}

.numberCard {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.12px;
}

.expiry {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #565467;
}

.primary {
    background: linear-gradient(91.73deg, #1A67C1 12.45%, #0F32AF 89.61%);
    height: 21px;
    padding: 2px 6px 3px 6px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16.39px;
    color: white;
    margin-left: 6px;
}

.dropdown {
    display: inline-flex;
    position: relative;
    min-height: 45px;
    width: 100% !important;
    box-sizing: border-box;
    gap: 6px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-top: 0px;
    border: 2px solid #BFC2D4;
    border-radius: 4px;
    cursor: pointer;
}

.dropdownbtn{
    position: absolute;
    right: 6px;
    top: 22px;
    writing-mode: vertical-rl;
    font-weight: bold;
    font-size: 14px;
}

.cardContainer {
    border: 2px solid #BFC2D4;
    border-top: none !important;
    border-bottom: none !important;
    border-radius: 4px;
}

.card {
    border-top: none;
    border-bottom: 2px solid #BFC2D4 !important;
}