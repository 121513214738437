.item {
  font-family: inherit;
  padding-left: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.12px;
  letter-spacing: 0cap;
}

.checkBox {
  position: relative;
  width: 20px;
  height: 20px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 2px solid #ccc;
  border-radius: 2px;
  cursor: pointer;
  outline: none;
  background-color: white;
}

.checkBox:checked {
  border-color: #1053d4;
  background-color: #1053d4;
}

.checkBox:checked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  background: url('data:image/svg+xml;utf8,<svg width="12" height="9" viewBox="0 -2 11 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 3.5L4.68421 7L11 1" stroke="white" stroke-width="1.5"/></svg>')
    no-repeat center;
  background-size: contain;
}
